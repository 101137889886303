import React from "react";

export const Message = ({ message }) => {

    const timestampToHours = (timestamp) => {
        let timezoneOffset = -3;
        let shiftedDate = new Date(new Date(timestamp).getTime() + timezoneOffset * 60 * 60 * 1000);
        return shiftedDate.toLocaleTimeString();
    };

    return <div key={message.id} className="message-container">
        <div className="message-header">
            <div className="message-name">{message.userId === 1 ? 'Martín' : 'Erika'}</div>
            <div className="message-time">{timestampToHours(message.created)}</div>
        </div>
        <div>{message.text}</div>
    </div>
}