import { useEffect, useCallback } from "react";

const apiUrl = process.env.REACT_APP_API_URL;
const vapidPublicKey = 'BHARwD5n4zq8qZBzzWTsM0Hf6p_q1MFLfbLchhOxEn4AvGzx1EMwsvGMnjMoeZ2_3Gtmlkp5yS3TdCeZbteO4CQ'

export const Notifications = () => {

    const askNotifications = useCallback(() => {
        if ('Notification' in window && navigator.serviceWorker) {
            Notification.requestPermission().then(function (permission) {
                if (permission === 'granted') {
                    console.log('Notification permission granted.');
                } else {
                    console.log('Notification permission denied.');
                }
            });
        }
    }, [])

    const registerServiceWorker = useCallback(async () => {
        if ('serviceWorker' in navigator) {
            const registration = await navigator.serviceWorker.register('/sw.js')
            const subscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(vapidPublicKey)
            });
            console.log('User is subscribed:', subscription);
            saveSubscriptionInBackend(subscription);
        }
    }, [])

    const saveSubscriptionInBackend = async (data) => {
        const options = {
            method: 'POST',
            body: JSON.stringify({data}),
            headers: {
              "Content-Type": "application/json",
              "token": localStorage.getItem('token')
            }
          };
        const response = await fetch(`${apiUrl}/notification-subscription`, options);
    }

    useEffect(() => {
        registerServiceWorker()
        askNotifications()
    }, [registerServiceWorker, askNotifications])

    function urlBase64ToUint8Array(base64String) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
          .replace(/-/g, '+')
          .replace(/_/g, '/');
      
        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);
      
        for (let i = 0; i < rawData.length; ++i) {
          outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
      }

    const sendNotification = () => {
        // Simulate a push event for testing
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.ready.then(function (registration) {
                registration.showNotification('Hello from your PWA!', {
                    body: 'This is a sample notification.',
                    icon: '/images/icon.png'
                });
            }).catch(function (error) {
                console.error('Error during service worker registration:', error);
            });
        }
    }

    return <div>
        <button onClick={sendNotification}>Notification</button>
    </div>

}
