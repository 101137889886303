
import React from "react";
import {useNavigate} from "react-router-dom"

const apiUrl = process.env.REACT_APP_API_URL;

export const Login = () => {
    const [user, setUser] = React.useState('');
    const [password, setPassword] = React.useState('');
    const navigate = useNavigate();

    const login = async () => {
        const options = {
            method: 'POST',
            body: JSON.stringify({ user, password }),
            headers: {
              "Content-Type": "application/json"
            }
          };
        const response = await fetch(`${apiUrl}/login`, options);   
        const responseJson = await response.json()
        if (responseJson.token) {
            const token = responseJson.token
            localStorage.setItem('token', token)
            navigate('/chat')
        } else {
            alert(`Error: ${responseJson.error}`)
        }
    }

    return (
        <div className="login-container">
            <div>
                <div>
                User:
                </div>
                <input placeholder="write tu usuario" value={user} onChange={(e) => setUser(e.target.value)}></input>
            </div>
            <div>
                <div>
                Password:
                </div>
                <input
                    type="password" 
                    placeholder="write tu password" 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)}>
                </input>
            </div>
            <button onClick={login}>Login</button>
        </div>
    );
}
