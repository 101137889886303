import React from "react";
import "./style.css";
import { Message } from "./components/Message.tsx"
import EmojiPicker from 'emoji-picker-react';
import {useNavigate} from "react-router-dom"

const apiUrl = process.env.REACT_APP_API_URL;

function App() {

  const [message, setMessage] = React.useState('');
  const [messages, setMessages] = React.useState([]);
  const [sound, setSound] = React.useState(true);
  const audio = new Audio('sound.mp3');
  const [emoji, setEmoji] = React.useState(false);
  const navigate = useNavigate();

  const addEmoji = (emoji) => {
    setMessage (message + emoji.emoji)
    console.log (emoji.emoji)
  }
  
  
  const listRef = React.useRef(null);

  React.useEffect(() => {
    scrollToBottom()
  }, [messages]);

  React.useEffect(() => {
    actualizar();
  }, []);

  React.useEffect(() => {
    setInterval(() => {
      actualizar();
    }, 1000);
  }, []);

  const scrollToBottom = () => {
    listRef.current?.lastElementChild?.scrollIntoView({ behavior: 'smooth' })
  }

  const actualizar = async () => {
    const options = {
      headers: {
        "token": localStorage.getItem('token')
      }
    };
    const response = await fetch(`${apiUrl}/all-messages`, options);
    const messages = await response.json();

    if (!messages.error) {
      setMessages(messages);
    }
  };
  
  const send = async (e) => {
    e.preventDefault();
    if (message === '') {
      return;
    }
    const options = {
      method: 'POST',
      body: JSON.stringify({ message }),
      headers: {
        "Content-Type": "application/json",
        "token": localStorage.getItem('token')
      }
    };
    await fetch(`${apiUrl}/send`, options);

    const newMessage = {
      text: message,
      created: new Date().getTime() + 3 * 60 * 60 * 1000,
    };

    setMessages([...messages, newMessage])
    playSound();
    setMessage('');
    actualizar();
    setEmoji(false);
  };
  
  const textChange = (e) => {
    setMessage(e.currentTarget.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      send(e);
    }
  };
  
  const toggleSound = () => {
    setSound(!sound);
  }
  const toggleEmoji = () => {
    setEmoji(!emoji)
  }
  
  const playSound = () => {
    if (sound) {
      audio.play()
    }
  }

  const logout = () => {
    localStorage.removeItem('token')
    navigate('/')
  }

  return (
    <div className="full">
      <div className="container">
        <div className="chat-header">
          <div className="logout-button">
            <button onClick={logout}>X</button>
          </div>
          <h2>Chat App</h2>
        </div>
        <div className="messages-container" ref={listRef}>
          {messages.map(e => (
            <Message message={e} />
          ))}
        </div>

        <form onSubmit={send}> 
          <EmojiPicker open={emoji} onEmojiClick={addEmoji} className="emoji-picker"/>
          <div className="controls-container">
            <input
              value={message}
              onChange={textChange}
              onKeyDown={handleKeyDown} // tecla Enter
              className="text-input"
              placeholder="Escribe un mensaje..."
              />
            <button type="submit">Send</button>
            <button onClick={toggleEmoji} > 🙂 </button> 
            {/* <button type="button" onClick={changeUser}>{user}</button> */}
            <button type="button" onClick={toggleSound}>{sound ? '🔊' : '🔇'}</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default App;
